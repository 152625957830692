import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import widgetReducer from "../features/widget/Slice";
import { v1Api } from "./ApiV1";
import { setCookie } from "cookies-next";

// Create store instance outside of makeStore
const makeStore = () =>
  configureStore({
    reducer: {
      widget: widgetReducer,
      [v1Api.reducerPath]: v1Api.reducer
    },
    middleware: (getDefaultMiddleware): any => [
      ...getDefaultMiddleware({
        serializableCheck: false
      }),
      v1Api.middleware,

      // Custom middleware for cookie handling
      (storeApi: any) => (next: any) => (action: any) => {
        const { botId, appearance } = storeApi.getState().widget;

        // Check if botId exists
        if (botId && appearance.clientSessionId) {
          setCookie(
            `Louise_Widget_${botId}`,
            JSON.stringify({
              botId,
              clientSessionId: appearance.clientSessionId
            }),
            {
              maxAge: 30 * 60,
              sameSite: "none", // Changed to none to allow cross-origin cookies in iframes
              secure: true, // Must be true when sameSite is none
              path: "/"
            }
          );

          next(action);
        } else {
          // If no botId, continue with the action
          next(action);
        }
      }
    ],
    devTools: process.env.NODE_ENV !== "production"
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
