import { AppState } from "@/lib/Store";
import { IMessage, ISetting } from "@/share/interfaces/ISetting";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

type AcceptableWidgetType = "text" | "video" | "live-text" | "live-video";

export interface IWidgetState {
  botId: string;
  type: AcceptableWidgetType | null;
  token: string;
  appearance: ISetting | null;
  messages: IMessage[];
  configs: {
    isLiveWidget: boolean;
    isInDelayMode: boolean;
    shouldHideExpandButton: boolean;
    clientWindowDimension: {
      width: number;
      height: number;
    };
  };
}

const initialState: IWidgetState = {
  botId: "",
  token: "",
  type: null,
  appearance: null,
  messages: [],
  configs: {
    isLiveWidget: false,
    isInDelayMode: false,
    shouldHideExpandButton: false,
    clientWindowDimension: {
      width: 1080,
      height: 1920
    }
  }
};

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    handleInitializeWidget: (
      state,
      action: PayloadAction<{
        initialData: { appearance: ISetting; messages: IMessage[] };
        botId: string;
        token: string | null;
        clientWindowDimension: {
          width: number;
          height: number;
        };
      }>
    ) => {
      state.botId = action.payload.botId;
      state.token = action.payload.token ?? "";
      state.appearance = action.payload.initialData.appearance;
      state.messages = action.payload.initialData.messages;
      state.configs = {
        isLiveWidget: action.payload.token ? true : false,
        isInDelayMode: action.payload.initialData.messages.find(
          (item) => item.data?.type === "delay"
        )
          ? true
          : false,
        shouldHideExpandButton: false,
        clientWindowDimension: action.payload.clientWindowDimension
      };
    },
    addMessage: (
      state,
      action: PayloadAction<{
        messages: IMessage[];
        clientSessionId: string;
      }>
    ) => {
      state.messages = [...state.messages, ...action.payload.messages];
      if (state.appearance) {
        state.appearance.clientSessionId = action.payload.clientSessionId;
      }
    },
    handleStartDelayMode: (state, action: PayloadAction<boolean>) => {
      state.configs.isInDelayMode = action.payload;
    },
    handleToggleExpandButtonShow: (state, action: PayloadAction<boolean>) => {
      state.configs.shouldHideExpandButton = action.payload;
    },
    addAiMessage: (
      state,
      action: PayloadAction<{ id: string; restOfMessage: string }>
    ) => {
      const lastMessage = state.messages.at(-1);

      if (
        lastMessage &&
        lastMessage.data &&
        lastMessage.id === action.payload.id &&
        lastMessage.userType === "bot"
      ) {
        lastMessage.data.value =
          lastMessage.data?.value + action.payload.restOfMessage;
      } else {
        state.messages.push({
          id: action.payload.id,
          data: {
            type: "text",
            value: action.payload.restOfMessage
          },
          userType: "bot",
          config: {
            inputStatus: "visible"
          }
        });
      }
    },
    handleUpdateClientSession: (state, action: PayloadAction<string>) => {
      if (state.appearance) {
        state.appearance.clientSessionId = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE.toString(),
      (state, action: PayloadAction<AppState>) => {
        return {
          ...state,
          ...action.payload.widget
        };
      }
    );
  }
});

export const {
  handleInitializeWidget,
  addMessage,
  handleStartDelayMode,
  handleToggleExpandButtonShow,
  addAiMessage,
  handleUpdateClientSession
} = widgetSlice.actions;

export default widgetSlice.reducer;

export const selectMessages = (state: AppState) => state.widget.messages;

export const selectLastMessage = (state: AppState) =>
  state.widget.messages[state.widget.messages.length - 1];

export const selectSetting = (state: AppState) => state.widget.appearance;

export const selectLatestInputConfig = (state: AppState) =>
  [...state.widget.messages]
    ?.reverse()
    ?.find((message) => message.config !== undefined);

export const selectConfigs = (state: AppState) => state.widget.configs;
